import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as searchActions from '../actions/searchActionCreators';
import {extractPaginationParams} from '../tools/request';
import {buildFetchProductsFilters} from './products';
import * as productsActions from '../actions/productsActionCreators';

const {API, ACTIONS} = constants;

export const fetchSearchSuggestionsRequest = (params) => RequestTools.ajax({url: API.SEARCH_SUGGESTIONS_PATH, params: params});
export const fetchSearchResultsRequest = (params) => RequestTools.ajax({url: API.SEARCH_PATH, params: params});
export const fetchSearchFiltersRequest = (params) => RequestTools.ajax({url: API.SEARCH_FILTERS_PATH, params: params});

export function * fetchSearchSuggestions(action) {
  try {
    const response = yield call(fetchSearchSuggestionsRequest, {query: action.payload.query});

    yield put(searchActions.fetchSearchSuggestionsSuccess(response.data));
  } catch (e) {
    yield put(searchActions.fetchSearchSuggestionsFailed());
  }
}

export function * fetchSearchResults(action) {
  const
    {searchState} = yield select(),
    params: any = {query: action.payload.query},
    {paginator: {page, per_page}, filters, sort} = searchState,
    filterParams: any = buildFetchProductsFilters(filters);

  params.q = filterParams;

  params.sort_name = sort.name;
  params.sort_dest = sort.dest;

  params.page = page;
  params.per_page = per_page;

  yield put(searchActions.resetSearchResultsOffers());

  try {
    const response = yield call(fetchSearchResultsRequest, params);

    yield put(searchActions.fetchSearchResultsSuccess(response.data, extractPaginationParams(response)));
  } catch (e) {
    yield put(searchActions.fetchSearchResultsFailed());
  }
}

export function * fetchSearchFilters(action) {
  try {
    const response = yield call(fetchSearchFiltersRequest, {query: action.payload.query});

    yield put(searchActions.fetchProductsFilterContextSuccess(response.data));
  } catch (e) {
    yield put(searchActions.fetchProductsFilterContextFailed());
  }
}

export function * changeQuery(action) {
  yield put(searchActions.resetProductsFilters());
  yield put(searchActions.resetProductsFilterContext());
  yield put(searchActions.fetchProductsFilterContext(action.payload.query));
  yield put(searchActions.fetchSearchResults(action.payload.query));
}
export function * filterChanged() {
  yield put(searchActions.fetchProductsCount());
}

export function * reloadProducts() {
  const
    {searchState} = yield select();
  yield put(searchActions.fetchSearchResults(searchState.query));
}

export function * loadPossibleProductsCount() {
  const
    {searchState} = yield select(),
    params: any = {query: searchState.query},
    {filters} = searchState,
    filterParams: any = buildFetchProductsFilters(filters);

  params.q = filterParams;
  params.page = 1;
  params.per_page = 1;
  console.log(params, filterParams)
  try {
    const
      response = yield call(fetchSearchResultsRequest, params),
      paginator = extractPaginationParams(response);

    yield put(searchActions.fetchProductsCountSuccess(paginator.total));
  } catch (e) {
    yield put(searchActions.fetchProductsCountFailed());
  }
}

function * searchSaga() {
  yield takeEvery(ACTIONS.FETCH_SEARCH_SUGGESTIONS, fetchSearchSuggestions);
  yield takeEvery(ACTIONS.FETCH_SEARCH_RESULTS, fetchSearchResults);
  yield takeEvery(ACTIONS.FETCH_SEARCH_FILTERS_CONTEXT, fetchSearchFilters);
  yield takeEvery(ACTIONS.SET_SEARCH_QUERY, changeQuery);
  yield takeEvery(ACTIONS.SET_SEARCH_FILTER, filterChanged);
  yield takeEvery([
    ACTIONS.FETCH_SEARCH_PRODUCTS_COUNT
  ], loadPossibleProductsCount);
  yield takeEvery([
    ACTIONS.SET_SEARCH_PAGINATION,
    ACTIONS.RESET_SEARCH_FILTERS,
    ACTIONS.APPLY_SEARCH_FILTERS,
    ACTIONS.CHANGE_SEARCH_SORT
  ], reloadProducts);

}

export default searchSaga;
