import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import ChevronIcon from '../Icons/Chevron';

import './style.scss';
import './mobile.scss';

const Breadcrumbs = ({breadcrumbs, applicationState, ...props}) => {
  return (
    <ul className='breadcrumbs' itemScope itemType="https://schema.org/BreadcrumbList">
      <li key='arrow' className='breadcrumbs__item'>
        <ChevronIcon direction='left' opacity={!props.skipRoot ? '.4' : '1'} color='#000' />
      </li>
      {
        !props.skipRoot ?
          <li key='home' className='breadcrumbs__item' itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to={constants.ROUTES.REGIONAL_HOME_PATH(applicationState.current_city.slug)} itemProp="item">
              <em itemProp="name">Главная</em>
            </Link>
          </li>
          : null
      }
      {
        breadcrumbs.map((crumb, idx) => {
          return (
            <li key={idx} className='breadcrumbs__item' itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              {crumb.path ? <Link to={crumb.path} itemProp="item"><em itemProp="name">{crumb.label}</em></Link> : <span itemProp="item"><em itemProp="name">{crumb.label}</em></span>}
            </li>
          );
        })
      }
    </ul>
  );
}

const
  mapStateToProps = ({applicationState, rubricsState}) => {
    return {
      applicationState,
      rubricsState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(Breadcrumbs);
