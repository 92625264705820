import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as productsActions from '../../actions/productsActionCreators';
import ProductShowImages from './Images';
import Traits from './Traits';
import ReviewsWidget from './ReviewsWidget';
import FavouritesWidget from './FavouritesWidget';
import ShareWidget from './ShareWidget';
import OrderWidget from './OrderWidget';
import CommonWidget from './CommonWidget';
import GiftIcon from '../Icons/Gift';
import ChevronIcon from '../Icons/Chevron';
import ProductsList from '../ProductsList';
import ReviewsList from '../Reviews/List';
import MobileImages from './Mobile/Images';
import QuestionIcon from '../Icons/Question';
import DownloadIcon from '../Icons/Download';
import CalcIcon from '../Icons/Calc';
import DeliveryIcon from '../Icons/Delivery';
import LocationLargeIcon from '../Icons/LocationLarge';
import MobileQuestionDialog from './Mobile/QuestionDialog';
import MobileDocumentsDialog from './Mobile/DocumentsDialog';
import MobileCalculatorsDialog from './Mobile/CalculatorDialog';
import Button from '../Button';

import './style.scss';
import './mobile.scss';
import MobileReviewsDialog from './Mobile/ReviewsDialog';

const ProductShow = ({product, ...props}) => {
  const
    [mobileQuestionDialogOpened, setMobileQuestionDialogOpened] = useState(false),
    [mobileDocumentsDialogOpened, setMobileDocumentsDialogOpened] = useState(false),
    [mobileCalculatorsDialogOpened, setMobileCalculatorsDialogOpened] = useState(false),
    [mobileReviewsDialogOpened, setMobileReviewsDialogOpened] = useState(false),
    {interestsProducts, analogueProducts, currentProductReviews} = props.productsState,
    {is_mobile} = props.applicationState;

  return (
    <>
      {
        is_mobile ? null
          :
          <div className='container'>
            <h1>{product?.name}</h1>
          </div>
      }
      <div className='product-show-page__wrapper' itemscope="" itemtype="https://schema.org/Product">
        {
          is_mobile ? null
            :
            <div className='product-page-part container'>
              <div className='product-page-part__top-sidebar'>
                <ReviewsWidget offer={product} />
                {product.vendor_code ? <span className='text-gray-40'>Артикул: {product.vendor_code}</span> : null}
                <FavouritesWidget />
                <ShareWidget />
              </div>
            </div>
        }
        <div className='product-page-part product-page-part__top container'>
          <div className='product-page-part__col product-page-part__col_1'>
            {
              is_mobile ?
                <MobileImages images={product.product_images} />
                : <ProductShowImages images={product.product_images} mainImage={product.image} />
            }
            {
              is_mobile ?
                <div>
                  <h1>{product?.name}</h1>
                  <ReviewsWidget offer={product} onClick={is_mobile ? () => setMobileReviewsDialogOpened(true) : null} />
                </div>
                :null
            }

          </div>
          <div className='product-page-part__col product-page-part__col_2'>
            <div className='order-widget__wrapper'>
              <div className='order-widget__wrapper-top'>
                <OrderWidget offer={product} />
              </div>
              <div className='order-widget__wrapper-bottom'>
                <CommonWidget
                  icon={<GiftIcon />}
                  className='common'
                  title={<span className='text-blue'>Инструмент в подарок</span>}
                  value={<span className='text-black'>Только до 17 ноября</span>}
                />
                <ChevronIcon direction='right' />
              </div>
            </div>
          </div>
        </div>

        {
          product.description ?
            <div className='product-page-part container'>
              <h2>О товаре</h2>
              <div className='product-page__description html-content' dangerouslySetInnerHTML={{__html: product.description}} />
            </div>
            : null
        }

        {
          product.traits.bottom?.length || product.traits.top?.length ?
            <div className='product-page-part container'>
              <h2>Характеристики</h2>
              <div className={`flex ${is_mobile ? 'flex-column gap-8' : 'flex-row gap-32'}`}>
                <Traits traits={product.traits.bottom} />
                <Traits traits={product.traits.top} />
              </div>
            </div>
            :null
        }

        {
          is_mobile ?
            <div className='product-page-part container'>
              <div className='product-order-widget__row product-order-widget__group no-bg'>
                <CommonWidget
                  icon={<QuestionIcon />}
                  title='Есть вопрос?'
                  value='Консультации специалиста'
                  onClick={is_mobile ? () => setMobileQuestionDialogOpened(true) : null}
                />
                <MobileQuestionDialog open={mobileQuestionDialogOpened} onClose={() => setMobileQuestionDialogOpened(false)} />

                {
                  product.documents?.length ?
                    <>
                      <CommonWidget
                        icon={<DownloadIcon />}
                        title='Скачать документы'
                        value='Сертификаты и заключения'
                        onClick={is_mobile ? () => setMobileDocumentsDialogOpened(true) : null}
                      />
                      <MobileDocumentsDialog
                        documents={product.documents}
                        open={mobileDocumentsDialogOpened}
                        onClose={() => setMobileDocumentsDialogOpened(false)}
                      />
                    </>
                    : null
                }

                {
                  product.calculator ?
                    <>
                      <CommonWidget
                        icon={<CalcIcon />}
                        title='Сделать расчёты'
                        value='Калькулятор'
                        onClick={is_mobile ? () => setMobileCalculatorsDialogOpened(true) : null}
                      />
                      <MobileCalculatorsDialog
                        calculators={[product.calculator]}
                        open={mobileCalculatorsDialogOpened}
                        onClose={() => setMobileCalculatorsDialogOpened(false)}
                      />
                    </>
                    : null
                }
              </div>
            </div>
            : null
        }


        {
          interestsProducts.length ?
            <div className='product-page-part container'>
              <h2>С этим товаром покупают</h2>
              <div className='flex flex-row gap-32'>
                <ProductsList products={interestsProducts} colsCount={6} listClass={ is_mobile ? `mobile-inline` : ''} />
              </div>
            </div>
            : null
        }

        {
          currentProductReviews.length ?
            <div className='product-page-part container'>
              <h2>Отзывы</h2>
              <div className='flex flex-row gap-16'>
                <ReviewsList reviews={currentProductReviews} limit={is_mobile ? 2 : null} className='reviews-list_inline' />
              </div>
              {
                is_mobile ?
                  <>
                    <Button className='clean md w-full' onClick={() => setMobileReviewsDialogOpened(true)} >Смотреть все отзывы</Button>
                    <MobileReviewsDialog
                      reviews={currentProductReviews}
                      open={mobileReviewsDialogOpened}
                      onClose={() => setMobileReviewsDialogOpened(false)}
                    />
                  </>
                  : null
              }
            </div>
            : null
        }

        {
          analogueProducts.length ?
            <div className='product-page-part container'>
              <h2>Похожие товары</h2>
              <div className='flex flex-row gap-32'>
                <ProductsList products={analogueProducts} colsCount={6} listClass={ is_mobile ? `mobile-inline` : ''} />
              </div>
            </div>
            : null
        }

        {
          is_mobile ?
            <div className='product-page-part container'>
              <h2>Почему мы?</h2>
              <div className='product-order-widget__row product-order-widget__group no-bg'>
                <CommonWidget icon={<DeliveryIcon outlined />} title='Прямые поставки' value='От официального дистрибьютора' />
                <CommonWidget icon={<LocationLargeIcon outlined />} title='Бонусная программа' value='Копите баллы с каждой покупки' onClick={is_mobile ? () => null : null} />
                <CommonWidget icon={<DownloadIcon />} title='Подберем материалы' value='Найдем оптимальное решение' onClick={is_mobile ? () => null : null} />
              </div>
            </div>
            : null
        }
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      productsActions: bindActionCreators(productsActions, dispatch)
    };
  },

  mapStateToProps = ({productsState, railsContext, applicationState}) => {
    return {
      applicationState,
      productsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(ProductShow);
