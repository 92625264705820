import React from 'react';
import {priceWithCurrency} from '../../../tools/formatters';
import SmallOrderPositions from '../SmallOrderPositions';
import './style.scss';

const SuccessOrderPositions = ({orderInfo, ...props}) => {
  const {order} = orderInfo;
  return (
    <div className='success-order'>
      <div className='success-order__header'>
        {priceWithCurrency(order.subtotal)}
      </div>
      <div className='success-order__body'>
        {
          order.delivery_address ?
            <div className='success-order__delivery'>
              <div className='success-order__delivery-title'>Доставка</div>
              {order.delivery_address}
            </div>
           : null
        }
        {
          order.available_positions?.length ?
            <div className='success-order__positions'>
              <SmallOrderPositions positions={order.available_positions} />
            </div>
            : null
        }
        {
          order.unavailable_positions?.length ?
            <div className='success-order__positions'>
              <div className='success-order__positions-title'>Нет в наличии</div>
              <SmallOrderPositions positions={order.unavailable_positions} />
            </div>
            : null
        }
      </div>
      {props.footer ? <div className='success-order__footer'>{props.footer}</div> : null}
    </div>
  );
}

export default SuccessOrderPositions;
