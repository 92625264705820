import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';
import constants from '../../app/constants';

export const fetchCart = () => ({
  type: ACTIONS.FETCH_CART,
  [pendingTask]: begin
});

export const fetchCartSuccess = (cart) => ({
  type: ACTIONS.FETCH_CART_SUCCESS,
  payload: {cart},
  [pendingTask]: end
});

export const fetchCartFailed = (cart) => ({
  type: ACTIONS.FETCH_CART_FAILED,
  [pendingTask]: end
});

export const addToCart = (offerId, qty, unitId) => ({
  type: ACTIONS.ADD_TO_CART,
  payload: {
    offerId, qty, unitId
  },
  [pendingTask]: begin
});
export const addToCartSuccess = (cart, offerId) => ({
  type: ACTIONS.ADD_TO_CART_SUCCESS,
  payload: {
    cart,
    offerId
  },
  [pendingTask]: end
});
export const addToCartFailed = (offerId) => ({
  type: ACTIONS.ADD_TO_CART_FAILED,
  payload: {offerId},
  [pendingTask]: end
});

export const setCartPositionQty = (offerId, qty, unitId, mode) => ({
  type: ACTIONS.SET_CART_POSITION_QTY,
  payload: {offerId, qty, unitId, mode},
  [pendingTask]: begin
});
export const setCartPositionQtySuccess = (cart) => ({
  type: ACTIONS.SET_CART_POSITION_QTY_SUCCESS,
  payload: {cart},
  [pendingTask]: end
});
export const setCartPositionQtyFailed = () => ({
  type: ACTIONS.SET_CART_POSITION_QTY_SUCCESS,
  [pendingTask]: end
});

export const changeCartPosition = (offerId, changes) => ({
  type: ACTIONS.CHANGE_CART_POSITION,
  payload: {offerId, changes},
  [pendingTask]: begin
});
export const changeCartPositionSuccess = (cart) => ({
  type: ACTIONS.CHANGE_CART_POSITION_SUCCESS,
  payload: {cart},
  [pendingTask]: end
});
export const changeCartPositionFailed = () => ({
  type: ACTIONS.CHANGE_CART_POSITION_FAILED,
  [pendingTask]: end
});


export const removeCartPositions = (offerIds, mode) => ({
  type: ACTIONS.REMOVE_CART_POSITIONS,
  payload: {offerIds, mode},
  [pendingTask]: begin
});
export const removeCartPositionsSuccess = (cart) => ({
  type: ACTIONS.REMOVE_CART_POSITIONS_SUCCESS,
  payload: {cart},
  [pendingTask]: end
});
export const removeCartPositionsFailed = () => ({
  type: ACTIONS.REMOVE_CART_POSITIONS_FAILED,
  [pendingTask]: end
});

export const setCartPositionsForOrder = (cart_item_ids) => ({
  type: ACTIONS.SET_CART_POSITIONS_FOR_ORDER,
  payload: {cart_item_ids},
  [pendingTask]: begin
});

export const setCartPositionsForOrderSuccess = (cart) => ({
  type: ACTIONS.SET_CART_POSITIONS_FOR_ORDER_SUCCESS,
  payload: {cart},
  [pendingTask]: end
});

export const setCartPositionsForOrderFailed = () => ({
  type: ACTIONS.SET_CART_POSITIONS_FOR_ORDER_FAILED,
  [pendingTask]: end
});

export const sendOrder = (orderParams) => ({
  type: ACTIONS.CART_SEND_ORDER,
  payload: {orderParams},
  [pendingTask]: begin
});

export const sendOrderSuccess = (data) => ({
  type: ACTIONS.CART_SEND_ORDER_SUCCESS,
  payload: {data},
  [pendingTask]: end
});

export const sendOrderFailed = (data) => ({
  type: ACTIONS.CART_SEND_ORDER_FAILED,
  payload: {data},
  [pendingTask]: end
});

export const resetOrderErrors = () => ({
  type: ACTIONS.RESET_ORDER_ERRORS
});

export const setOrderErrors = (errors) => ({
  type: ACTIONS.SET_ORDER_ERRORS,
  payload: {errors}
});

export const resetSuccessOrder = () => ({
  type: ACTIONS.RESET_SUCCESS_ORDER
});
