import React from 'react';
import ImageBox from '../../ImgBox';
import './style.scss';

const SmallOrderPositions = ({positions}) => {
  return (
    <div className='small-order-positions__list'>
      {
        positions.map(position => {
          return (
            <>
              <div key={position.id} className='small-order-positions__item'>
                <ImageBox className='with-border' url={position.product?.image?.thumb} height={48} width={48} />
              </div>
            </>
          );
        })
      }
    </div>
  )
}

export default SmallOrderPositions;
