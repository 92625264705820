import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as searchActions from '../../actions/searchActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import {useMount, useSearchParam, useUnmount} from 'react-use';
import SeoTags from '../../routes/SeoTags';
import {textKeysReplacement} from '../../tools/helpers';
import ProductsList from '../../components/ProductsList';
import './styles.scss';
import {productsCountFrom} from '../../tools/formatters';
import ProductsFilters from '../../components/ProductsFilters';
import Button from '../../components/Button';
import ProductsSort from '../../components/ProductsSort';
import MobileProductsFilters from '../../components/ProductsFilters/MobileFilters';
import Paginator from '../../components/Paginator';


const SearchPage = (props) => {
  const
    searchQuery = useSearchParam('q'),
    {searchState} = props,
    {possibleProductsCount} = props.searchState,
    filtersOn = !!Object.keys(props.searchState.filters).length,
    handleApplyFilters = () => {
      props.searchActions.applyProductsFilters();
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    },
    countStr = possibleProductsCount ? `${possibleProductsCount} ${productsCountFrom(possibleProductsCount)}` : '',
    handlePaginatorChanged = (page, perPage) => {
      props.searchActions.paginatorChanged(page, perPage);
    },
    {is_mobile} = props.applicationState;


  useMount(() => {
    props.searchActions.setSearchQuery(searchQuery);
  });

  useUnmount(() => {
    props.searchActions.resetSearchResults();
  });

  return (
    <Layout
      className='search-page'
      breadcrumbs={[
        {label: 'Поиск'}
      ]}
    >
      {
        props.applicationState ?
          <SeoTags
            controller_action='search#index'
            seo_context={{
              title: textKeysReplacement('Поиск', props.applicationState.default_seo_context),
              description: textKeysReplacement('Поиск', props.applicationState.default_seo_context),
              keywords: textKeysReplacement('Поиск', props.applicationState.default_seo_context),
            }}
          />
          : null
      }

      <div className='container'>
        <h1>{searchQuery} {searchState.paginator?.total} {productsCountFrom(searchState.paginator?.total)}</h1>
        <div className='two-cols-container'>
          {
            !is_mobile ?
              <div className='two-cols-container__col two-cols-container__col_left'>
                <ProductsFilters
                  state={props.searchState}
                  actions={props.searchActions}
                />
                {
                  filtersOn && possibleProductsCount ?
                    <Button primary onClick={handleApplyFilters}>{`Показать ${countStr}`}</Button>
                    : null
                }
              </div>
              : null
          }
          <div className='two-cols-container__col two-cols-container__col_right'>
            <>
              <div className='products-sort-and-filters-wrapper'>
                <ProductsSort
                  state={props.searchState}
                  actions={props.searchActions}
                />
                {
                  is_mobile ?
                    <MobileProductsFilters
                      state={props.searchState}
                      actions={props.searchActions}
                    />
                    : null
                }
              </div>
              <ProductsList products={searchState.offers} showSkeleton={!searchState.collection_loaded} />
              <Paginator {...props.searchState.paginator} onChange={handlePaginatorChanged}/>
            </>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      searchActions: bindActionCreators(searchActions, dispatch)
    };
  },

  mapStateToProps = ({searchState, railsContext, applicationState}) => {
    return {
      applicationState,
      searchState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(SearchPage));
