import React, {useState} from 'react';

import './style.scss';
import './mobile.scss';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {Input} from 'antd';
import CloseIcon from '../../Icons/Close';
import InputPhone from '../../InputPhone';
let formTimeout = null;
const OrderUserForm = ({applicationState, data, onChange, ...props}) => {
  const
    [localVal, setLocalVal]:any = useState(data.recipient || {}),
    {cartState} = props,
    hasNameError = cartState.orderErrors?.user_name,
    handleSubmit = (result) => {
      onChange && onChange(result);
    },

    handleChange = (attr, type = 'input') => (event) => {
      const newRecipient = {
        ...localVal,
        [attr]: type === 'boolean' ? event.target.checked : event.target.value
      }

      setLocalVal(newRecipient);

      clearTimeout(formTimeout);

      formTimeout = setTimeout(() => handleSubmit(newRecipient), 500);
    },
    handleContactChanged = (attr) => (value) => {
      const newRecipient = {
        ...localVal,
        [attr]: value
      }

      setLocalVal(newRecipient);

      clearTimeout(formTimeout);

      formTimeout = setTimeout(() => handleSubmit(newRecipient), 500);
    };

  return (
    <div className='form form_user'>
      <div className='form__row form__row_3-cols'>
        <div className='form__field'>
          <div className='form__field-label'>Фамилия</div>
          <div className='form__field-input'>
            <Input
              value={localVal.last_name}
              placeholder='Фамилия'
              className={`${hasNameError ? 'error' : ''}`}
              onChange={handleChange('last_name')}
              suffix={<CloseIcon />}
            />
          </div>
        </div>
        <div className='form__field'>
          <div className='form__field-label'>Имя</div>
          <div className='form__field-input'>
            <Input
              value={localVal.first_name}
              placeholder='Фамилия'
              className={`${hasNameError ? 'error' : ''}`}
              onChange={handleChange('first_name')}
              suffix={<CloseIcon />}
            />
          </div>
        </div>
        <div className='form__field'>
          <div className='form__field-label'>Отчество</div>
          <div className='form__field-input'>
            <Input
              value={localVal.patronymic}
              placeholder='Фамилия'
              className={`${hasNameError ? 'error' : ''}`}
              onChange={handleChange('patronymic')}
              suffix={<CloseIcon />}
            />
          </div>
        </div>
      </div>
      <div className='form__row form__row_2-cols'>
        <div className='form__field'>
          <div className='form__field-label'>Телефон</div>
          <div className='form__field-input'>
            <InputPhone
              value={localVal.phone}
              className={`${cartState.orderErrors?.user_phone ? 'error' : ''}`}
              placeholder='Телефон'
              onChange={handleContactChanged('phone')}
            />
          </div>
        </div>
        <div className='form__field'>
          <div className='form__field-label'>Почта</div>
          <div className='form__field-input'>
            <Input
              type='email'
              value={localVal.email}
              placeholder='Почта'
              onChange={handleChange('email')}
              className={`${cartState.orderErrors?.user_email ? 'error' : ''}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(OrderUserForm);

