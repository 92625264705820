import ACTIONS from '../constants/actions';
import {cloneObject} from '../tools/helpers';
import Offer from '../decorators/offer';
import CartItem from '../decorators/cart_item';

export const initialState = {
  available_positions: [],
  unavailable_positions: [],
  orderSending: false,
  orderErrors: null,
  successOrder: null,
  addingToCartOfferIds: [],
  info: {
    itemsForOrder: []
  }
};

const rebuildCartResponse = (cartResponse) => {
  const
    itemsForOrder = [],
    available_items = [],
    unavailable_items = [];
  cartResponse.cart_items.forEach(item => {
    const new_item = cloneObject(item);
    new_item.offer = new Offer(item.offer);

    if (item.for_order) {
      itemsForOrder.push(item.id);
    }

    item.available_qty && available_items.push(new CartItem(new_item));
    item.unavailable_qty && unavailable_items.push(new CartItem(new_item));
  });

  return {
    available_positions: available_items,
    unavailable_positions: unavailable_items,
    info: {
      ...cartResponse.info,
      itemsForOrder
    }
  }
};

const cartReducer = function(state = initialState, action) {
  const actionHandlers = {
    [ACTIONS.FETCH_CART_SUCCESS]() {
      return {
        ...state,
        ...rebuildCartResponse(action.payload.cart)
      };
    },
    [ACTIONS.ADD_TO_CART]() {
      const addingToCart = [...state.addingToCartOfferIds];
      addingToCart.push(action.payload.offerId);
      return {
        ...state,
        addingToCartOfferIds: addingToCart
      };
    },
    [ACTIONS.ADD_TO_CART_SUCCESS]() {
      const
        addingToCart = [...state.addingToCartOfferIds],
        idx = addingToCart.findIndex(id => id === action.payload.offerId);
      addingToCart.splice(idx, 1);
      return {
        ...state,
        ...rebuildCartResponse(action.payload.cart),
        addingToCartOfferIds: addingToCart
      };
    },
    [ACTIONS.ADD_TO_CART_FAILED]() {
      const
        addingToCart = [...state.addingToCartOfferIds],
        idx = addingToCart.findIndex(id => id === action.payload.offerId);
      addingToCart.splice(idx, 1);
      return {
        ...state,
        addingToCartOfferIds: addingToCart
      };
    },
    [ACTIONS.REMOVE_CART_POSITIONS_SUCCESS]() {
      return {
        ...state,
        ...rebuildCartResponse(action.payload.cart)
      };
    },
    [ACTIONS.SET_CART_POSITION_QTY_SUCCESS]() {
      return {
        ...state,
        ...rebuildCartResponse(action.payload.cart)
      };
    },
    [ACTIONS.CHANGE_CART_POSITION_SUCCESS]() {
      return {
        ...state,
        ...rebuildCartResponse(action.payload.cart)
      };
    },
    [ACTIONS.SET_CART_POSITIONS_FOR_ORDER_SUCCESS]() {
      return {
        ...state,
        ...rebuildCartResponse(action.payload.cart)
      };
    },
    [ACTIONS.CART_SEND_ORDER]() {
      return {
        ...state,
        orderSending: true
      };
    },
    [ACTIONS.CART_SEND_ORDER_SUCCESS]() {
      return {
        ...state,
        orderSending: false,
        successOrder: action.payload.data
      };
    },
    [ACTIONS.RESET_SUCCESS_ORDER]() {
      return {
        ...state,
        orderSending: false,
        successOrder: null
      };
    },
    [ACTIONS.CART_SEND_ORDER_FAILED]() {
      return {
        ...state,
        orderSending: false
      };
    },
    [ACTIONS.SET_ORDER_ERRORS]() {
      return {
        ...state,
        orderErrors: action.payload.errors
      };
    },
    [ACTIONS.RESET_ORDER_ERRORS]() {
      return {
        ...state,
        orderErrors: null
      };
    },
  };

  if (action.type in actionHandlers) {
    return actionHandlers[action.type]();
  }

  return state;
};

export default cartReducer;
