import constants from '../constants';
import {cloneObject} from '../../app/tools/helpers';
import Offer from '../decorators/offer';

export const initialState = {
  collection: [],
  currentRubric: null,
  homeCategoriesPreview: [],
  rubricLoaded: false,
  currentRubricsCache: {},
  flat_collection: []
};
const
  massUpdateProducts = (state, marker, source) => {
    if (!state.currentRubric?.children?.length && !state.homeCategoriesPreview.length) { return state; }

    [...(state.currentRubric?.children || []), ...state.homeCategoriesPreview].forEach(collection => {
      if (collection.productsPreviews) {
        collection.productsPreviews = marker(collection.productsPreviews, source);
      }
    });

    return state;
  },
  updateProductsInCartQty = (state, cart) => massUpdateProducts(state, markInCart, cart),
  updateProductsInFavourites = (state, favourites) => massUpdateProducts(state, markInFavourites, favourites),

  rubrics = (state = cloneObject(initialState), action) => {
    const actionHandlers = {
      [constants.ACTIONS.FETCH_ROOT_RUBRICS_SUCCESS]() {
        return {
          ...state,
          collection: action.payload.rubrics
        };
      },

      [constants.ACTIONS.FETCH_RUBRIC_SUCCESS]() {
        return {
          ...state,
          currentRubric: action.payload.rubric,
          currentRubricsCache: {...state.currentRubricsCache, [action.payload.rubric.joined_path]: action.payload.rubric},
          rubricLoaded: true
        };
      },

      [constants.ACTIONS.RESET_RUBRIC]() {
        return {
          ...state,
          currentRubric: null,
          rubricLoaded: false
        };
      },

      [constants.ACTIONS.FETCH_RUBRICS_TREE_SUCCESS]() {
        const currentRubrics = cloneObject(state.collection);
        return {
          ...state,
          collection: action.payload.rubrics.map((rubric) => {
            const current = currentRubrics.find((r) => r.id === rubric.id);
            if (current) {
              rubric.brands = current?.brands;
            }
            
            return rubric;
          })
        };
      },

      [constants.ACTIONS.RESET_RUBRICS_TREE]() {
        return {
          ...state,
          collection: []
        };
      },
      
      [constants.ACTIONS.FETCH_RUBRIC_BRANDS_SUCCESS]() {
        const rubric = cloneObject(state.currentRubric);
        rubric.brands = action.payload.brands;
        return {
          ...state,
          currentRubric: rubric
        };
      },
      
      [constants.ACTIONS.FETCH_RUBRIC_BEST_OFFERS_SUCCESS]() {
        const rubric = cloneObject(state.currentRubric);
        rubric.offers = action.payload.offers.map(p => (new Offer(p)));
        return {
          ...state,
          currentRubric: rubric
        };
      },
      
      [constants.ACTIONS.FETCH_RUBRIC_STOCKS_SUCCESS]() {
        const rubric = cloneObject(state.currentRubric);
        rubric.stocks = action.payload.stocks.map(p => (new Offer(p)));
        return {
          ...state,
          currentRubric: rubric
        };
      },
    };

    if (action.type in actionHandlers) {
      return actionHandlers[action.type]();
    }

    return state;
  };

export default rubrics;
