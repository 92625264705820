import React from 'react';
import _ from 'lodash';
import {Button as AntBtn} from 'antd';
import {Link} from 'react-router-dom';

import './style.scss';
import './mobile.scss';

type StandardProps = React.ComponentProps<typeof Button>

interface Props extends StandardProps {
  success?: boolean
  primary?: boolean
  secondary?: boolean
  danger?: boolean
  to?: string
}

const
  TYPES = ['success', 'primary', 'danger', 'secondary'],
  Button = ({type = 'primary', to = null, success = false, primary = false, danger = false, secondary = false, ...props}): JSX.Element => {
    const
      typeProps = {success, primary, danger, secondary},
      typeClass = _.compact(TYPES.map(item => typeProps[item] ? item : null)).join(''),
      className = `st-btn ${typeClass || type || ''} ${props.className || ''} ${props.loader ? 'loading' : ''}`,
      children = props.children,
      handleLinkClick = (e) => {
        (props.disabled || props.loading) && e.preventDefault();
      };

    delete props.children;

    return to ? (
      <Link to={to} onClick={handleLinkClick} className={`${className} ${props.disabled ? 'disabled' : ''}`}>
        {props.loader ? <span className='loader' /> : null}
        {children}
      </Link>
    ) : (
      <AntBtn
        {...props}
        type='text'
        className={className}
      >
        {props.loader ? <span className='loader' /> : null}
        {children}
      </AntBtn>
    );
  };
export default Button;
