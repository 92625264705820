import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import * as rubricsActions from '../../actions/rubricsActionCreators'

import {useUnmount} from 'react-use';
import ProductRubric from './ProductRubric';
import RubricContent from './RubricContent';
import RubricBottomContent from '../../components/RubricBottomContent';
import constants from '../../constants';
import {productsCountFrom} from '../../tools/formatters';
import Button from '../../components/Button';
import ProductsList from '../../components/ProductsList';
import BrandsPreviewList from '../../components/BrandsPreviewList';

const RubricPage = (props) => {
  const
    [currentPath, setCurrentPath] = useState(null),
    {is_mobile, current_city} = props.applicationState,
    rubricPath = props.match.params[0],
    currentRubric = props.rubricsState.currentRubric;

  useEffect(() => {
    if (rubricPath === currentPath) return;
    props.rubricsActions.fetchRubric(rubricPath);
    setCurrentPath(rubricPath);
  }, [rubricPath, props.rubricsActions.fetchRubric]);

  useUnmount(() => {
    props.rubricsActions.resetRubric();
  });

  return (
    <Layout
      breadcrumbs={[
        ...(currentRubric ? currentRubric.ancestors.map(rubric => ({
          label: rubric.title,
          path: constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, rubric.joined_path)
        })) : []),
        {label: currentRubric?.title}
      ]}
    >
      <div className='container'>
        <h1>
          {currentRubric?.title}
          {
            is_mobile ?
              <>
                &nbsp;
                <div className='counters'>{currentRubric?.total_products_count || 0} {productsCountFrom(currentRubric?.total_products_count || 0)}</div>
              </>
              : null
          }
        </h1>
        {
           props.rubricsState.rubricLoaded && currentRubric ? (
             currentRubric.children.length ? <RubricContent rubric={currentRubric} /> : <ProductRubric rubric={currentRubric}/>
           ) :null
        }
        {
          currentRubric?.stocks?.length ?
            <section className='page-content-section'>
              <h3>
                Акции
              </h3>
              <ProductsList products={currentRubric.stocks} colsCount={6} listClass={ is_mobile ? `mobile-inline` : ''} />
            </section>
            : null
        }
        {
          currentRubric?.brands?.length ?
            <section className='page-content-section'>
              <h3>
                Производители
              </h3>
              <BrandsPreviewList brands={currentRubric.brands} />
            </section>
            : null
        }

        {
          currentRubric?.offers?.length ?
            <section className='page-content-section'>
              <h3>
                Новинки
              </h3>
              <ProductsList products={currentRubric.offers} colsCount={6} listClass={ is_mobile ? `mobile-inline` : ''} />
            </section>
            : null
        }
      </div>
      {
        currentRubric?.rubric_content ? <RubricBottomContent content={currentRubric.rubric_content} rubric={currentRubric} /> : null
      }
    </Layout>
  );
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      rubricsActions: bindActionCreators(rubricsActions, dispatch)
    };
  },
  mapStateToProps = ({rubricsState, railsContext, applicationState}) => {
    return {
      applicationState,
      rubricsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(RubricPage));
