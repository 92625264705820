export default {
  CART_PATH: '/cart',
  CART_SET_QTY_PATH: '/cart/set_qty',
  CART_CHANGE_UNIT_PATH: '/cart/change_unit',
  SET_CART_POSITIONS_FOR_ORDER_PATH: '/cart/update_for_order',
  CART_ORDER_PATH: '/cart/order',
  ROOT_RUBRICS_PATH: '/rubrics/roots',
  RUBRICS_TREE_PATH: '/rubrics/tree',
  RUBRICS_HOME_PATH: '/rubrics/home',
  BRANDS_PATH: '/brands',
  BRAND_PATH: (id = ':id') => `/brands/${id}`,
  BRAND_RUBRICS_PATH: (id = ':id') => `/brands/${id}/rubrics`,
  BRANDS_FOR_RUBRIC_PATH: '/brands/best_for_rubric',
  RUBRICS_PATH: (path) => `/rubrics/${path}`,
  PRODUCTS_PATH: '/products',
  RUBRIC_BEST_OFFERS_PATH: '/products/best_for_rubric',
  RUBRIC_STOCK_OFFERS_PATH: '/products/stocks_for_rubric',
  SEARCH_PATH: '/search',
  SEARCH_FILTERS_PATH: '/search/filters',
  SEARCH_SUGGESTIONS_PATH: '/search/suggestions',
  PRODUCTS_FILTERS_PATH: '/products/filters',
  HOME_BEST_SELLERS_PATH: '/products/best_sellers',
  HOME_CONTENT_PATH: '/home',
  HOME_TOP_SLIDES: '/slides/top_home',
  HOME_TOP_MOBILE_SLIDES: '/slides/top_home_mobile',
  HOME_BOTTOM_SLIDES: '/slides/bottom_home',
  HOME_BUILDINGS: '/buildings/home_page',
  PRODUCT_PATH: (id = ':id'): string => `/products/${id}`,
  ANALOGUE_PRODUCT_PATH: (id = ':id'): string => `/products/${id}/analogues`,
  INTERESTS_PRODUCT_PATH: (id = ':id'): string => `/products/${id}/interests`,
  PRODUCT_REVIEWS_PATH: (id = ':id'): string => `/products/${id}/reviews`,
  PRODUCT_BRANCH_STATS_PATH: (id = ':id'): string => `/products/${id}/branch_stats`,
  ORDERS_PATH: '/orders',
  SESSION_PATH: '/sessions',
  SESSION_CSRF_PATH: '/sessions/csrf',
  STORES_PATH: '/stores',
  CITIES_PATH: '/cities',
  CITY_PATH: (id = ':id'): string => `/cities/${id}`,
  STOCKS_PATH: '/stocks',
  STOCK_PATH: (id = ':id'): string => `/stocks/${id}`,
  ARTICLES_PATH: '/articles',
  ARTICLE_PATH: (id = ':id'): string => `/articles/${id}`,
  EVENTS_PATH: '/events',
  EVENT_PATH: (id = ':id'): string => `/events/${id}`,
  USER_SUBSCRIBE_PATH: '/user_requests/subscribe',
  USER_QUESTION_PATH: '/user_requests/question',

  OTP_LOGIN_PATH: '/buyer/otp_login',
  OTP_SIGN_PATH: '/users/sign_in',
  OTP_REFRESH_PATH: '/buyer/get_otp'
};
