import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';

export const setSearchQuery = (query) => ({
  type: ACTIONS.SET_SEARCH_QUERY,
  payload: {query}
});

export const fetchSearchResults = (query) => ({
  type: ACTIONS.FETCH_SEARCH_RESULTS,
  payload: {query},
  [pendingTask]: begin
});
export const fetchSearchResultsSuccess = (searchResults, paginator) => ({
  type: ACTIONS.FETCH_SEARCH_RESULTS_SUCCESS,
  payload: {searchResults, paginator},
  [pendingTask]: end
});
export const fetchSearchResultsFailed = () => ({
  type: ACTIONS.FETCH_SEARCH_RESULTS_FAILED,
  [pendingTask]: end
});

export const resetSearchResults = () => ({
  type: ACTIONS.RESET_SEARCH_RESULTS
});

export const resetSearchResultsOffers = () => ({
  type: ACTIONS.RESET_SEARCH_RESULTS_OFFERS
});

export const fetchSearchSuggestions = (query) => ({
  type: ACTIONS.FETCH_SEARCH_SUGGESTIONS,
  payload: {query},
  [pendingTask]: begin
});

export const fetchSearchSuggestionsSuccess = (suggestions) => ({
  type: ACTIONS.FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  payload: {suggestions},
  [pendingTask]: end
});

export const fetchSearchSuggestionsFailed = () => ({
  type: ACTIONS.FETCH_SEARCH_SUGGESTIONS_FAILED,
  [pendingTask]: end
});

export const resetSearchSuggestions = () => ({
  type: ACTIONS.RESET_SEARCH_SUGGESTIONS
});

export const updatePaginator = (pagination) => ({
  type: ACTIONS.UPDATE_SEARCH_PAGINATION,
  payload: {pagination}
});

export const updateSort = (sort) => ({
  type: ACTIONS.UPDATE_SEARCH_SORT,
  payload: {sort}
});

export const paginatorChanged = (page, per_page) => ({
  type: ACTIONS.SET_SEARCH_PAGINATION,
  payload: {
    pagination: {page, per_page}
  }
});

export const fetchProductsFilterContext = (searchQuery) => ({
  type: ACTIONS.FETCH_SEARCH_FILTERS_CONTEXT,
  payload: {query: searchQuery}
});

export const fetchProductsFilterContextSuccess = (filters) => ({
  type: ACTIONS.FETCH_SEARCH_FILTERS_CONTEXT_SUCCESS,
  payload: {filters}
});

export const fetchProductsFilterContextFailed = () => ({
  type: ACTIONS.FETCH_SEARCH_FILTERS_CONTEXT_FAILED
});

export const resetProductsFilterContext = () => ({
  type: ACTIONS.RESET_SEARCH_FILTERS_CONTEXT
});

export const setProductsFilters = (filters) => ({
  type: ACTIONS.SET_SEARCH_FILTERS,
  payload: {filters}
});

export const setProductsFilter = (filterName, filterValue) => ({
  type: ACTIONS.SET_SEARCH_FILTER,
  payload: {filterName, filterValue}
});

export const resetProductsFilters = () => ({
  type: ACTIONS.RESET_SEARCH_FILTERS
});

export const applyProductsFilters = () => ({
  type: ACTIONS.APPLY_SEARCH_FILTERS
});

export const resetProductsFilter = (filterName) => ({
  type: ACTIONS.RESET_SEARCH_FILTERS,
  payload: {filterName}
});

export const changeSort = (name, dest) => ({
  type: ACTIONS.CHANGE_SEARCH_SORT,
  payload: {name, dest}
});

export const fetchProductsCount = () => ({
  type: ACTIONS.FETCH_SEARCH_PRODUCTS_COUNT
});
export const fetchProductsCountSuccess = (total) => ({
  type: ACTIONS.FETCH_SEARCH_PRODUCTS_COUNT_SUCCESS,
  payload: {total}
});
export const fetchProductsCountFailed = () => ({
  type: ACTIONS.FETCH_SEARCH_PRODUCTS_COUNT_FAILED
});
