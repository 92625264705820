import React from 'react';
import TextSnippet from '../TextSnippet';

import './style.scss';
import './mobile.scss';

const RubricBottomContent = ({content, rubric}) => {
  return (
    <div className='rubric-content'>
      <div className='container'>
        <div className='rubric-content__header'>
          <h2>{content.header}</h2>
        </div>
        <TextSnippet text={content.body} drawerTitle={rubric.title} drawerBodyHeader={content.header} />
      </div>
    </div>
  );
};

export default RubricBottomContent;

