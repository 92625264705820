import React, {useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import './style.scss';
import {inGroups} from '../../tools/helpers';
import ChevronIcon from '../Icons/Chevron';
import BrandsPreviewList from '../BrandsPreviewList';

const RubricsMenu = (props) => {
  const
    {collection} = props.rubricsState,
    [selectedRubric, setSelectedRubric] = useState(null),
    [menuOpened, setMenuOpened] = useState(false),
    handleRubricClick = (e) => {
      setMenuOpened(false);
    },
    handleMouseEnter = (rubric) => (e) => {
      setSelectedRubric(rubric);
      setMenuOpened(true);
    },
    handleMouseLeave = (e) => {
      setMenuOpened(false);
    };

  return (
    <>
      <div className='rubrics-menu' onMouseLeave={handleMouseLeave}>
        <div className='container'>
          <div className='rubrics-menu__wrapper'>
            <ul className='rubrics-menu__list'>
              {
                [
                  {
                    title: 'КАТАЛОГ',
                    id: 0,
                    path: constants.ROUTES.REGIONAL_CATALOGUE_PATH(props.applicationState.current_city.slug)
                  },
                  ...collection
                ].map((item) => (
                  <li
                    className={`rubrics-menu__list-item ${selectedRubric?.id === item.id ? 'active' : ''}`}
                    key={item.id}
                    onMouseEnter={item.id ? handleMouseEnter(item) : null}
                    onClick={handleRubricClick}
                  >
                    <Link
                      to={item.path ? item.path : constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, item.joined_path)}
                    >
                      {item.title}
                    </Link>
                  </li>)
                )
              }
            </ul>
          </div>
        </div>
      </div>
      {
        menuOpened ?
          <>
            <div className='rubrics-menu-output__wrapper' onMouseEnter={() => setMenuOpened(true)} onMouseLeave={handleMouseLeave}>
              <div className='container'>
                <div className='rubrics-menu-output'>
                  <div className='rubrics-menu-output__left'>
                    <ul className='rubrics-menu-output__left-list'>
                      {
                        collection.map((item) => (
                          <li className={`rubrics-menu__list-item ${selectedRubric?.id === item.id ? 'active' : ''}`} key={item.id} onMouseEnter={handleMouseEnter(item)}>
                            <Link
                              to={constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, item.joined_path)}
                              onClick={handleRubricClick}
                            >
                              {item.title}
                              <ChevronIcon direction='right' height={16} width={16} opacity={'0.2'} />
                            </Link>
                          </li>)
                        )
                      }
                    </ul>
                  </div>
                  <div className='rubrics-menu-output__center'>
                    {
                      inGroups(selectedRubric.children, 3).map((rubricGroup:any, idx) => {
                        return (
                          <div key={idx} className='rubrics-menu-output__col'>
                            {
                              rubricGroup.map((item) => (
                                <div key={item.id} className='rubrics-menu-output__item'>
                                  <Link
                                    to={constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, item.joined_path)}
                                    onClick={handleRubricClick}
                                  >
                                    {item.title}
                                  </Link>
                                  {
                                    item.children?.length
                                     ?
                                      <ul className='rubric-list__lvl-2'>
                                        {
                                          item.children.map((iteml2) => (
                                            <li className='rubric-list__lvl-2-item' key={iteml2.id}>
                                              <Link
                                                to={constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, iteml2.joined_path)}
                                                onClick={handleRubricClick}
                                              >
                                                {iteml2.title}
                                              </Link>
                                            </li>)
                                          )
                                        }
                                      </ul>
                                      : null
                                  }
                                </div>)
                              )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className='rubrics-menu-output__right'>
                    {
                      selectedRubric.brands?.length ?
                        <BrandsPreviewList
                          mobileInline={false}
                          brands={selectedRubric.brands}
                          onClick={handleRubricClick}
                          className={'column'}
                        />
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='rubrics-menu-output__shadow' />
          </>
          : null
      }
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
  },

  mapStateToProps = ({applicationState, rubricsState}) => {
    return {
      applicationState,
      rubricsState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(RubricsMenu);
