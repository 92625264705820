import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import * as rubricsActions from '../../actions/rubricsActionCreators'
import RubricsPreviewList from '../../components/RubricsPreviewList';
import SubscriptionForm from '../../components/Forms/Subscription';
import Slider from '../../components/Slider';
import SeoTags from '../../routes/SeoTags';
import PageSeoSnippet from '../../components/PageSeoSnippet';


const RootRubricsPage = (props) => {
  const
    {is_mobile, current_city} = props.applicationState,
    {flat_collection} = props.rubricsState;

  return (
    <Layout
      breadcrumbs={[
        {label: 'Каталог'}
      ]}
    >
      <SeoTags controller_action='products#rubrics' />
      <div className='container'>
        <h1>
          Каталог {flat_collection.length}
        </h1>
        <section className='page-content-section'>
          <RubricsPreviewList rubrics={flat_collection} />
        </section>

        {
          !is_mobile ?
            <PageSeoSnippet controller_action='products#rubrics' />
            : null
        }

        <section className='page-content-section message-form-section'>
          {
            is_mobile ?
              <div className='message-form__wrapper'>
                <SubscriptionForm />
              </div>
              : <>
                <div className='message-form-section__col message-form-section__col_1'>
                  <Slider slides={props.applicationState.homeSlidesBottom} className='bottom' />
                </div>
                <div className='message-form-section__col message-form-section__col_2'>
                  <div className='message-form__wrapper'>
                    <SubscriptionForm />
                  </div>
                </div>
              </>
          }
        </section>
      </div>
    </Layout>
  );
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      rubricsActions: bindActionCreators(rubricsActions, dispatch)
    };
  },
  mapStateToProps = ({rubricsState, railsContext, applicationState}) => {
    return {
      applicationState,
      rubricsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(RootRubricsPage));
