import ACTIONS from '../constants/actions';
import Offer from '../decorators/offer';
import {cloneObject} from '../../app/tools/helpers';
import {DEFAULT_FILTERS, DEFAULT_PAGINATOR, DEFAULT_SORT} from './products.js'
import constants from '../constants';
export const initialState = {
  loading: false,
  collection_loaded: false,
  query: '',
  offers: [],
  brands: [],
  rubrics: [],
  filters: {...DEFAULT_FILTERS},
  filtersContext: {},
  paginator: cloneObject(DEFAULT_PAGINATOR),
  sort: {...DEFAULT_SORT},
  search_phrase: '',
  words: null,
  possibleProductsCount: null,
  suggestions: {
    words: [],
    offers: [],
    brands: [],
    highlighter: [],
    rubrics: [],
    search_phrase: '',
    empty: false
  },
};

const searchReducer = function(state = initialState, action) {
  const actionHandlers = {
    [ACTIONS.FETCH_SEARCH_SUGGESTIONS]() {
      return {
        ...state,
        loading: true
      };
    },
    [ACTIONS.SET_SEARCH_QUERY]() {
      return {
        ...state,
        query: action.payload.query,
        collection_loaded: false
      };
    },
    [ACTIONS.FETCH_SEARCH_RESULTS_SUCCESS]() {
      action.payload.searchResults.offers = (action.payload.searchResults.offers || []).map(p => (new Offer(p)));
      return {
        ...state,
        ...action.payload.searchResults,
        paginator: {
          ...state.paginator,
          ...action.payload.paginator
        },
        collection_loaded: true,
        possibleProductsCount: null
      };
    },
    [ACTIONS.RESET_SEARCH_RESULTS]() {
      return {
        ...state,
        query: '',
        offers: [],
        brands: [],
        rubrics: [],
        paginator: {page: 1, per_page: 30, total: 0},
        search_phrase: '',
        words: null,
      };
    },
    [ACTIONS.RESET_SEARCH_RESULTS_OFFERS]() {
      return {
        ...state,
        offers: [],
        collection_loaded: false
      };
    },
    [ACTIONS.FETCH_SEARCH_SUGGESTIONS_SUCCESS]() {
      return {
        ...state,
        loading: false,
        suggestions: {
          ...action.payload.suggestions
        }
      };
    },
    [ACTIONS.FETCH_SEARCH_SUGGESTIONS_FAILED]() {
      return {
        ...state,
        loading: false
      };
    },
    [ACTIONS.RESET_SEARCH_SUGGESTIONS]() {
      return {
        ...state,
        loading: false,
        suggestions: {
          words: [],
          offers: [],
          brands: [],
          highlighter: [],
          rubrics: [],
          search_phrase: '',
          empty: false
        }
      };
    },

    [constants.ACTIONS.SET_SEARCH_FILTER]() {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue
        },
        paginator: {...state.paginator, ...cloneObject(DEFAULT_PAGINATOR)},
      };
    },

    [constants.ACTIONS.SET_SEARCH_FILTERS]() {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload.filters
        },
      };
    },

    [constants.ACTIONS.RESET_SEARCH_FILTERS]() {
      return {
        ...state,
        filters: {},
        paginator: cloneObject(DEFAULT_PAGINATOR)
      };
    },

    [constants.ACTIONS.FETCH_SEARCH_FILTERS_CONTEXT_SUCCESS]() {
      return {
        ...state,
        filtersContext: action.payload.filters
      };
    },

    [constants.ACTIONS.RESET_SEARCH_PAGINATION]() {
      return {
        ...state,
        paginator: cloneObject(DEFAULT_PAGINATOR)
      };
    },

    [constants.ACTIONS.RESET_SEARCH_FILTERS_CONTEXT]() {
      return {
        ...state,
        filtersContext: {}
      };
    },

    [constants.ACTIONS.SET_SEARCH_PAGINATION]() {
      return {
        ...state,
        paginator: {
          ...state.paginator,
          ...action.payload.pagination
        }
      };
    },

    [constants.ACTIONS.CHANGE_SEARCH_SORT]() {
      return {
        ...state,
        sort: {
          name: action.payload.name,
          dest: action.payload.dest,
        }
      };
    },

    [constants.ACTIONS.RESET_SEARCH_SORT]() {
      return {
        ...state,
        sort: DEFAULT_SORT
      };
    },
    [constants.ACTIONS.FETCH_SEARCH_PRODUCTS_COUNT_SUCCESS]() {
      return {
        ...state,
        possibleProductsCount: action.payload.total
      };
    },
  };

  if (action.type in actionHandlers) {
    return actionHandlers[action.type]();
  }

  return state;
};

export default searchReducer;
