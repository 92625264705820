import React from 'react';
import './style.scss';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class CustomInput extends React.Component<any, any> {
  render(): JSX.Element {
    return (
      <PhoneInput
        country='ru'
        onlyCountries={['ru']}
        containerClass='st-tel-input'
        inputClass={'st-input ant-input ' + (this.props.className || '')}
        disableDropdown={true}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        value={this.props.value}
      />
    );
  }
}

export default CustomInput;
