import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {textKeysReplacement} from '../../tools/helpers';
import TextSnippet from '../TextSnippet';

const PageSeoSnippet = ({seo_context = {}, ...props}) => {
    if (typeof window === 'undefined') { return null; }

    const
      {page_settings, default_seo_context} = props.applicationState,
      setting = page_settings.find(setting => setting.controller_action_name === props.controller_action);

    if (!setting) { return null; }

    const
      snippet = textKeysReplacement(setting.snippet, {...default_seo_context, ...seo_context}),
      description = textKeysReplacement(setting.description, {...default_seo_context, ...seo_context});

    return snippet ?
      <section className='page-content-section'>
        <div className='rubric-content'>
          {
            description ?
              <div className='rubric-content__header'>
                <h2>{description}</h2>
              </div>
              : null
          }
          <TextSnippet text={snippet} />
        </div>
      </section>
      : null;
  },

  mapStateToProps = ({applicationState}) => {
    return {applicationState};
  },

  withConnect = connect(
    mapStateToProps,
    null
  );

export default compose(withConnect)(PageSeoSnippet);
