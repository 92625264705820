import React from 'react';
import {useUnmount} from 'react-use';
import Button from '../../Button';
import constants from '../../../constants';
import './style.scss';
import SuccessOrderPositions from '../../Cart/SuccessOrderPositions';

const CartSuccessOrder = ({order, onExit, ...props}) => {

  useUnmount(() => {
    onExit && onExit();
  });

  return (
    <div className='cart-success-order'>
      <div className='cart-success-order__text'>
        Информация о заказе передана менеджерам, они могут связаться с вами для уточнения заказа.
      </div>
      {
        order.sbr_pay_params?.confirmation?.confirmation_url ?
          <div className='cart-success-order__text'>
            <p>Если у вас не открылось окно оплаты, перейдите по <a href={order.sbr_pay_params?.confirmation?.confirmation_url}>ссылке.</a></p>
          </div>
          : null
      }
      <SuccessOrderPositions
        orderInfo={order}
        footer={
          <Button
            secondary
            className='l w-full'
            to={constants.ROUTES.HOME}>
            На главную
          </Button>
        }
      />
    </div>
  );
};

export default CartSuccessOrder;
