import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import * as cartActions from '../../actions/cartActionCreators';
import {useMount, useUnmount} from 'react-use';
import constants from '../../constants';
import SeoTags from '../../routes/SeoTags';

const SuccessPaymentPage = (props) => {
  const
    {cartState, applicationState} = props,
    {is_mobile} = applicationState,
    orderId = props.match.params.order_id;
  useUnmount(() => {
    props.cartActions.resetSuccessOrder();
  });

  return (
    <Layout
      breadcrumbs={props.applicationState.is_mobile ? null : [
        {label: 'Успешная оплата'}
      ]}
      className='cart-page'
    >
      <SeoTags controller_action='cart#success_payment' />
      <div className='container'>
        {
          cartState.successOrder ?
            <h1>
              Заказ №{cartState.successOrder.order.id} {cartState.successOrder?.sbr_pay_params?.status === 'succeeded' ? ', успешно оплачен.' : ', оплата не завершена.'}
            </h1>
            : <h1>Заказ №{orderId} не найден.</h1>
        }
        <p>Венуться на <Link className='link' to={constants.ROUTES.REGIONAL_HOME_PATH(props.applicationState.current_city.slug)}>главную</Link>.</p>
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      cartActions: bindActionCreators(cartActions, dispatch)
    };
  },

  mapStateToProps = ({cartState, railsContext, applicationState}) => {
    return {
      applicationState,
      cartState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(SuccessPaymentPage));
