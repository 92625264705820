import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import * as cartActions from '../../actions/cartActionCreators';
import {useMount} from 'react-use';
import constants from '../../constants';
import OrderWizard from '../../components/OrderWizard';
import CartMobileSuccessOrder from '../../components/OrderWizard/Mobile/OrderSuccess';
import SeoTags from '../../routes/SeoTags';
import CartSuccessOrder from '../../components/OrderWizard/OrderSuccess';

const OrderPage = (props) => {
  const
    {cartState, applicationState} = props,
    {is_mobile} = applicationState;

  useMount(() => {
    props.cartActions.fetchCart();
  });

  return props.cartState.successOrder ?
    (
      is_mobile ?
        <Layout
          className='status-page'
          hideFooter
        >
          <SeoTags controller_action='cart#index' />
          <div className='container'>
            <CartMobileSuccessOrder
              order={props.cartState.successOrder}
              onExit={() => props.cartActions.resetSuccessOrder()} />
          </div>
        </Layout>
        :
        <Layout
          breadcrumbs={[
            {label: 'Корзина', path: constants.ROUTES.CART}
          ]}
          skipRootBreadcrumb
          className='order-page'
        >
          <div className='container'>
            <h1>
              Заказ {props.cartState.successOrder.order.id} принят
            </h1>
            <CartSuccessOrder
              order={props.cartState.successOrder}
              onExit={() => props.cartActions.resetSuccessOrder()} />
          </div>
        </Layout>
    )
    : (
    <Layout
      breadcrumbs={[
        {label: 'Назад в корзину', path: constants.ROUTES.CART}
      ]}
      skipRootBreadcrumb
      className='cart-page'
    >
      <div className='container'>
        <h1>
          Оформление заказа
        </h1>
        <OrderWizard order />
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      cartActions: bindActionCreators(cartActions, dispatch)
    };
  },

  mapStateToProps = ({cartState, railsContext, applicationState}) => {
    return {
      applicationState,
      cartState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(OrderPage));
