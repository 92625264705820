import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import PrivateRoute from './PrivateRoute';
import ROUTES from '../constants/routes';
import * as applicationActions from '../actions/applicationActionCreators';
import Home from '../pages/Home';
import ContactsPage from '../pages/Contacts';
import RubricPage from '../pages/Rubric';
import ProductPage from '../pages/ProductPage';
import CartPage from '../pages/Cart';
import OrderPage from '../pages/Order';
import BrandsPage from '../pages/Brands';
import BrandPage from '../pages/Brand';
import StocksPage from '../pages/Stocks';
import StockPage from '../pages/Stock';
import ArticlesPage from '../pages/Articles';
import ArticlePage from '../pages/Article';
import NotFoundPage from '../pages/NotFound';
import EventPage from '../pages/Event';
import EventsPage from '../pages/Events';
import SearchPage from '../pages/Search';
import SuccessPaymentPage from '../pages/SuccessPayment';
import RootRubricsPage from '../pages/RootRubrics';

export const extractProductId = (path) => {
  const
    parts = path.split('/'),
      id_part = parts[parts.length - 1],
      id = id_part.split('-')[0];
  return Number(id);
};
export const extractBrandId = (path) => {
  return Number(path.split('-')[0]);
};

export const cityConstraint = (cities, city_slug) => {
  return !!cities.find(city => city.slug === city_slug);
}

const WrappedRoute = ({path, component, ...rest}) => {
    if (rest.computedMatch.path !== rest.applicationState.currentRoute) {
      rest.actions.setCurrentRoute(rest.computedMatch.path);
    }

    if (rest.applicationState.redirect) {
      rest.actions.resetRedirect();
      return <Redirect to={rest.applicationState.redirect.path} />;
    }

    if (rest.private) {
      return <PrivateRoute {...{path, component, exact: rest.exact, authenticated: rest.customerState.currentCustomer?.id}} />;
    } else {
      return <Route {...{path, component, exact: rest.exact}} />;
    }
  },
  isProductPage = (path) => {
    return !!extractProductId(path);
  },
  CatalogueRoute = ({path, ...rest}) => {
    if (rest.computedMatch.path !== rest.applicationState.currentRoute) {
      rest.actions.setCurrentRoute(rest.computedMatch.path);
    }
    if (rest.applicationState.redirect) {
      rest.actions.resetRedirect();
      return <Redirect to={rest.applicationState.redirect.path} />;
    }

    return <Route {...{path, component: (isProductPage(rest.computedMatch.url) ? ProductPage : RubricPage), exact: rest.exact}} />;
  },

  Routes = (props: any) => {
    const currentReginSlug = props.applicationState.current_city.slug;
    return (
      <Switch>
        <WrappedRoute
          path={ROUTES.CART}
          component={CartPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.ORDER_PATH}
          component={OrderPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.CART_SUCCESS_PAYMENT_PATH}
          component={SuccessPaymentPage}
          exact
          {...props}
        />

        <Route path={ROUTES.HOME} component={() => <Redirect to={ROUTES.REGIONAL_HOME_PATH(currentReginSlug)} />} exact />
        <Route path={ROUTES.BRANDS_PATH} component={() => <Redirect to={ROUTES.REGIONAL_BRANDS_PATH(currentReginSlug)} />} exact />
        <Route path={ROUTES.EVENTS_PATH} component={() => <Redirect to={ROUTES.REGIONAL_EVENTS_PATH(currentReginSlug)} />} exact />
        <Route path={ROUTES.STOCKS_PATH} component={() => <Redirect to={ROUTES.REGIONAL_STOCK_PATH(currentReginSlug)} />} exact />
        <Route path={ROUTES.ARTICLES_PATH} component={() => <Redirect to={ROUTES.REGIONAL_ARTICLES_PATH(currentReginSlug)} />} exact />

        <WrappedRoute
          path={ROUTES.REGIONAL_CONTACTS_PATH()}
          component={ContactsPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_CATALOGUE_PATH()}
          component={RootRubricsPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.SEARCH_PATH}
          component={SearchPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_HOME_PATH()}
          component={Home}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_BRANDS_PATH()}
          component={BrandsPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_STOCK_PATH()}
          component={StockPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_STOCKS_PATH()}
          component={StocksPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_ARTICLE_PATH()}
          component={ArticlePage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_ARTICLES_PATH()}
          component={ArticlesPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_EVENT_PATH()}
          component={EventPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_EVENTS_PATH()}
          component={EventsPage}
          exact
          {...props}
        />

        <WrappedRoute
          path={ROUTES.REGIONAL_BRAND_PATH()}
          component={BrandPage}
          exact
          {...props}
        />

        <CatalogueRoute
          path={ROUTES.REGIONAL_RUBRIC_PATH()}
          {...props}
        />

        <CatalogueRoute
          path={ROUTES.REGIONAL_PRODUCT_PATH()}
          {...props}
        />

        <Route
          path='*'
          component={() => <NotFoundPage />}
        />
      </Switch>
    );
  },

  mapDispatchToProps = (dispatch) => {
    return {
      actions: bindActionCreators(applicationActions, dispatch),
    };
  },

  mapStateToProps = ({customerState, applicationState}) => {
    return {
      customerState, applicationState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(Routes);
