import React, {useState} from 'react';
import {Form, Input} from 'antd';
import Button from '../../Button';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as userRequests from '../../../actions/userRequestsActionCreators';

const SubscriptionForm = (props) => {
  const
    [email, setEmail] = useState(''),
    handleSubmit = () => {
      props.userRequestsActions.sendSubscribeForm({email});
    },
    handleEmailChange = (e) => {
      setEmail(e.target.value);
      props.userRequestsActions.changeSubscribeForm({errors: null});
    }

  return (
    <>
      {
        props.userRequestsState.subscribeForm.done ?
          <>
            <div className='message-form__title'>
              Заявка отправлена!
            </div>
          </>
          :
          <>
            <div className='message-form__title'>
              Хотите получать доступ к скидкам и акциям?
            </div>
            <div className='user-form'>
              <div className='user-form__row'>
                <div className={`user-form__row-input ${props.userRequestsState.subscribeForm?.errors?.email ? 'error' : ''}`}>
                  <Input value={email} onChange={handleEmailChange} placeholder='Ваша почта' />
                </div>
              </div>
              <div className='user-form__row'>
                <Button primary className='md w-full' onClick={handleSubmit} loader={props.userRequestsState.subscribeSending}>Подписаться</Button>
              </div>
              <div className='user-form__row'>
                <div className='user-form__row-help'>
                  По нажатию на кнопку, подтверждаю желание подписаться на рассылку и новости
                </div>
              </div>
            </div>
          </>
      }
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
    return {
      userRequestsActions: bindActionCreators(userRequests, dispatch),
    };
  },

  mapStateToProps = ({applicationState, railsContext, userRequestsState}) => {
    return {
      applicationState,
      railsContext,
      userRequestsState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(SubscriptionForm);
