import React, {useEffect, useState} from 'react';
import {Drawer, Dropdown, Image, Input, Select} from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import './style.scss';
import * as applicationActions from '../../../../actions/applicationActionCreators';
import * as searchActions from '../../../../actions/searchActionCreators';
import MobileSearchString from '../SearchString';
import {Link, Redirect, useHistory} from 'react-router-dom';
import constants from '../../../../constants';
import ImageBox from '../../../ImgBox';
import ChevronIcon from '../../../Icons/Chevron';
import Highlighter from "react-highlight-words";
import SearchIcon from '../../../Icons/Search';
import _ from 'lodash';
import CloseIcon from '../../../Icons/Close';
import Button from '../../../Button';

let searchTimer = null;

const MobileSearch = (props) => {
  const
    [query, setQuery] = useState(props.searchState.query || ''),
    [open, setOpen] = useState(false),
    {offers, brands, rubrics, highlighter, search_phrase, empty} = props.searchState.suggestions,
    history = useHistory(),
    isSearchPage = props.applicationState.currentRoute === constants.ROUTES.SEARCH_PATH,
    highlighters = _.isArray(highlighter) ? highlighter : [highlighter],
    city_slug = props.applicationState.current_city.slug,
    handleClose = () => {
      setOpen(false);
      setQuery('');
      props.onClose && props.onClose();
      props.searchActions.resetSearchSuggestions()
    },

    handleInputChange = (e) => {
      if (!query.length || query.length < 3) {
        props.searchActions.resetSearchSuggestions();
      }

      setQuery(e.target.value);
      clearTimeout(searchTimer);

      searchTimer = setTimeout(() => {
        props.searchActions.fetchSearchSuggestions(query);
      }, 600)
    },

    handlePreSearchSubmit = (e) => {
      e.preventDefault();
      setOpen(true);
      props.searchActions.fetchSearchSuggestions(query);
    },

    handleSearchSubmit = (e) => {
      e.preventDefault();
      e.preventDefault();
      props.searchActions.setSearchQuery(query);
      history.push(`${constants.ROUTES.SEARCH_PATH}?q=${query}`);
      props.searchActions.fetchSearchSuggestions(query);
    };

  useEffect(() => {
    if (props.searchState.query === query) { return; }
    setQuery(props.searchState.query);
  }, [props.searchState.query]);


  return (
    <div className='mobile-search'>
      {
        !open ?
          <>
            <form onSubmit={handlePreSearchSubmit}>
              <button type='submit' className='hidden-submit' />

              <MobileSearchString
                onChange={handleInputChange}
                onFocus={() => setOpen(true)}
                query={query}
                showClear={false}
                placeholder='Поиск по товарам'
              />
            </form>
          </>
          : null
      }
      <Drawer
        className='select-drawer search-result-drawer'
        getContainer='body'
        height='100%'
        placement='bottom'
        onClose={handleClose}
        footer={<Button onClick={handleClose} className='w-full md clean'>Закрыть</Button>}
        closeIcon={''}
        title={
          <form onSubmit={handleSearchSubmit}>
            <button type='submit' className='hidden-submit' />
            <MobileSearchString
              onChange={handleInputChange}
              autofocus={open}
              query={query}
              onClear={handleClose}
              placeholder='Поиск по товарам'
            />
          </form>
        }
        open={open}
      >
        <div className='burger-menu-drawer__body'>
          {
            empty ? <div className='search-results-block__empty'>
                По запросу «<b>{search_phrase}</b>» — ничего не найдено, попробуйте изменить запрос
            </div>
            :null
          }
          {
            offers.length ?
              <div className='search-results-block'>
                <div className='search-results-block__title'>Товары</div>
                <ul className='burger-menu-options search-results-block__list'>
                  {
                    offers.map((offer) => (
                      <li className='burger-menu-option search-results-block__list-item'>
                        <Link onClick={handleClose} to={constants.ROUTES.REGIONAL_PRODUCT_PATH(city_slug, offer.rubric_path, offer.id_with_slug)} >
                          <div className='search-results-block__list-item-img'>
                            <ImageBox url={offer.image.small} width={48} height={48} alt={offer.image.alt || offer.name} />
                          </div>
                          <div className='search-results-block__list-item-title'>
                            <Highlighter
                              highlightClassName="highlighted"
                              searchWords={highlighters}
                              autoEscape={true}
                              caseSensitive={false}
                              textToHighlight={offer.name}
                            />
                            <div className='search-results-block__list-item-subtitle'>{offer.rubric?.title}</div>
                          </div>
                          <ChevronIcon direction='right' opacity='.2' />
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
              : null
          }
          {
            rubrics.length ?
              <div className='search-results-block'>
                <div className='search-results-block__title'>Категории</div>
                <ul className='burger-menu-options search-results-block__list'>
                  {
                    rubrics.map((rubric) => (
                      <li className='burger-menu-option search-results-block__list-item'>
                        <Link to={constants.ROUTES.REGIONAL_RUBRIC_PATH(city_slug, rubric.joined_path)} >
                          <div className='search-results-block__list-item-img'>
                            <SearchIcon color='#000' height={24} width={24} />
                          </div>
                          <div className='search-results-block__list-item-title'>
                            <Highlighter
                              highlightClassName="highlighted"
                              searchWords={highlighters}
                              autoEscape={true}
                              caseSensitive={false}
                              textToHighlight={rubric.title}
                            />
                            <div className='search-results-block__list-item-subtitle'>Каталог</div>
                          </div>
                          <ChevronIcon direction='right' opacity='.2' />
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
              : null
          }
          {
            brands.length ?
              <div className='search-results-block'>
                <div className='search-results-block__title'>Бренды</div>
                <ul className='burger-menu-options search-results-block__list'>
                  {
                    brands.map((brand) => (
                      <li className='burger-menu-option search-results-block__list-item'>
                        <Link to={constants.ROUTES.REGIONAL_BRAND_PATH(city_slug, brand.id_with_slug)} >
                          <div className='search-results-block__list-item-img'>
                            <SearchIcon color='#000' height={24} width={24} />
                          </div>
                          <div className='search-results-block__list-item-title'>
                            <Highlighter
                              highlightClassName="highlighted"
                              searchWords={highlighters}
                              autoEscape={true}
                              caseSensitive={false}
                              textToHighlight={brand.title}
                            />
                          </div>
                          <ChevronIcon direction='right' opacity='.2' />
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
              : null
          }
        </div>
      </Drawer>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      applicationActions: bindActionCreators(applicationActions, dispatch),
      searchActions: bindActionCreators(searchActions, dispatch),
    };
  },

  mapStateToProps = ({applicationState, railsContext, searchState}) => {
    return {
      applicationState,
      railsContext,
      searchState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(MobileSearch);
