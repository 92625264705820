import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import ProductsListPlateItem from './PlateItem';
import * as productsActions from '../../actions/productsActionCreators';
import * as cartActions from '../../actions/cartActionCreators';

import './style.scss';
import './mobile.scss';
import ProductPlateItemSkeleton from './Skeleton';

const ProductsList = ({products, showSkeleton = false, ...props}) => {
  const inCartProducts = Object.keys(props.cartState.info?.products || {});
  return (
    <div
      className={`products-list products-list_cols-${props.colsCount || 4} ${props.listType === 'rows' ? 'products-list_rows' : ''} ${props.listClass ? props.listClass : ''}`}
      itemScope
      itemType="https://schema.org/ItemList"
    >
      {
        !showSkeleton ?
          products.map((product) => {
            return (<ProductsListPlateItem
              key={product.id}
              ssr={props.railsContext.serverSide}
              product={product}
              inCart={inCartProducts.includes('' + product.product_id)}
              city_slug={props.applicationState.current_city.slug}
              onAddToCart={props.cartActions.addToCart}
              sendingToCart={props.cartState.addingToCartOfferIds.includes(product.id)}
            />)
          })
          :
          [...'12345678'].map(i => <ProductPlateItemSkeleton key={i} />)
      }
    </div>
  );
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      productsActions: bindActionCreators(productsActions, dispatch),
      cartActions: bindActionCreators(cartActions, dispatch),
    };
  },
  mapStateToProps = ({applicationState, cartState, railsContext}) => {
    return {
      railsContext,
      applicationState,
      cartState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(ProductsList);
